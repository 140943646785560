import * as React from 'react';
import styled from 'styled-components';

import { JobDetails } from '../../components/JobDetails';
import { Body as BaseBody } from '../../components/Typography';
import embeddedImage from '../../content/env-work.jpg';

const Body = styled(BaseBody)({ maxWidth: 1000 });

const JobDetail = () => (
	<JobDetails
		name="Embedded Software Engineer"
		image={embeddedImage}
		color="#bf26b6"
		area="💻 Web Development"
		type="⏱ Full Time"
		location="📍 Stuttgart (Arena2036)"
		model="On Site & Remote"
		about={
			<Body>
				We are looking for an Embedded Software Engineer who will join our Engineering team and help
				us execute the complete embedded software development lifecycle. Your goal will be to create
				scalable and optimized software systems for various embedded platforms.. You should have
				decent experience with IT systems, software engineering techniques and different programming
				languages. You are interested in exploring new use cases and implementing innovative
				solutions. You maintain relevant knowledge about industry trends and upcoming technologies.
				<br />
				<br />
				We are looking for an Embedded Software Engineer who will join our Engineering team and help
				us execute the complete embedded software development lifecycle. Your goal will be to create
				scalable and optimized software systems for various embedded platforms.. You should have
				decent experience with IT systems, software engineering techniques and different programming
				languages. You are interested in exploring new use cases and implementing innovative
				solutions. You maintain relevant knowledge about industry trends and upcoming technologies.
			</Body>
		}
		responsibilities={[
			'Develop and implement software for embedded devices and systems based on requirements to production and commercial deployment',
			'Design, develop, code, test and debug system software',
			'Perform code and design reviews on a regular basis',
			'Estimate and measure efficiency, stability and scalability of system resources, and seek ways to enhance these metrics',
			'Validate new product designs prior to their implementation',
			'Assist QA Engineers to test software performance and functionality',
			'Organize post production support for systems you have delivered',
			'Analyze the hardware related to the systems you are working with, and troubleshoot problems if needed',
			'Find third party and open source software to use in your projects',
		]}
		requirements={[
			'2+ years experience working in software engineering',
			'Excellent knowledge of C or modern C++ programming and basic understanding of other programming languages such as Rust, JavaScript.',
			'Excellent knowledge of OS coding techniques, IP protocols, interfaces and hardware subsystems',
			'Solid experience in systems design for embedded operating systems',
			'Passion for hands-on development of innovative solutions for various embedded targets and hardware prototyping',
			'Solid knowledge of software configuration management tools, defect tracking tools, and peer review',
			'Familiarity with reading schematics and data sheets for components',
			'Strong documentation and writing skills',
			'BS degree in Computer Science or Engineering',
		]}
		qualification={[
			'Enthusiasm for new technologies',
			'Analytical thinking',
			'Ability to abstract',
			'Ability to work independently',
			'General enthusiasm for IoT',
		]}
		offer={[
			'Family-friendly working conditions through flexible working hours',
			'The greatest possible flexibility in your choice of work location - whether in bright, modern Arena2036 or in mobile work or home offices',
			'Extensive social benefits (e.g. various special vacations and allowances)',
			'Continuous training and development opportunities through annual employee appraisals',
			'High-quality individual work equipment',
			'A subsidy for local public transport',
			'Extensive workplace health promotion measures, e.g. fruit basket, coffee, drinks, etc.',
			'Finally, a dynamic working environment with flat hierarchies and a lot of team spirit',
		]}
	/>
);

export default JobDetail;
